define("emberfire/mixins/realtime-route", ["exports", "emberfire/services/realtime-listener"], function (_exports, _realtimeListener) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // TODO make sure realtime works on findAll
  //      handle includes
  var _default = Ember.Mixin.create({
    afterModel: function afterModel(model) {
      (0, _realtimeListener.subscribe)(this, model);
      return this._super(model);
    },
    deactivate: function deactivate() {
      (0, _realtimeListener.unsubscribe)(this);
      return this._super();
    }
  });

  _exports.default = _default;
});