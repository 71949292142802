define("emberfire/initializers/emberfire", ["exports", "firebase/app", "emberfire/services/firebase-app", "emberfire/services/realtime-listener", "emberfire/adapters/firestore", "emberfire/serializers/firestore", "emberfire/adapters/realtime-database", "emberfire/serializers/realtime-database"], function (_exports, _app, _firebaseApp, _realtimeListener, _firestore, _firestore2, _realtimeDatabase, _realtimeDatabase2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var firebase = _app.default;

  var initialize = function initialize(application) {
    var environment = application.resolveRegistration('config:environment');

    if (!environment || _typeof(environment.firebase) !== 'object') {
      throw new Error('Please set the `firebase` property in your environment config.');
    }

    if (typeof environment.firebase.length === 'undefined') {
      loadEnvironment(application, environment.firebase);
    } else {
      environment.firebase.forEach(function (config) {
        return loadEnvironment(application, config);
      });
    }

    application.register("service:realtime-listener", _realtimeListener.default.extend({}), {
      instantiate: true
    });
    application.register('adapter:-firestore', _firestore.default);
    application.register('serializer:-firestore', _firestore2.default);
    application.register('adapter:-realtime-database', _realtimeDatabase.default);
    application.register('serializer:-realtime-database', _realtimeDatabase2.default);
  };

  var loadEnvironment = function loadEnvironment(application, environment) {
    var config = Object.assign({}, environment);
    delete config.options;
    delete config.name;
    var options = Object.assign({}, config.options);
    options.name = options.name || environment.name;
    firebase.initializeApp(config, options);
    var serviceName = options.name === '[DEFAULT]' && "firebase-app" || "firebase-".concat(options.name);
    application.register("service:".concat(serviceName), _firebaseApp.default.extend({
      name: options.name
    }), {
      instantiate: true
    });
  };

  var _default = {
    name: 'emberfire',
    initialize: initialize
  };
  _exports.default = _default;
});