define("emberfire/mixins/performance-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    firebaseApp: Ember.inject.service('firebase-app'),
    store: Ember.inject.service('store'),
    router: Ember.inject.service('router'),
    trace: Ember.RSVP.reject(),
    init: function init() {
      this._super.apply(this, arguments);

      this.get('firebaseApp').performance(); // TODO see if I can fix this

      if (this.toString().indexOf("@route:application::") > 0) {
        throw "PerformanceRouteMixin does not work correctly in the application route";
      }
    },
    beforeModel: function beforeModel() {
      var _this = this;

      // TODO promise proxy
      this.set('trace', this.get('firebaseApp').performance().then(function (perf) {
        var trace = perf.trace("".concat(_this.toString(), ":didTransition"));
        trace.start();
        return trace;
      }));
    },
    afterModel: function afterModel() {
      var _this2 = this;

      var tracePromise = this.get('trace');
      var router = this.get('router');
      tracePromise.then(function (trace) {
        // TODO figure out how to disconnect the routeDidChange listener
        router.on('routeDidChange', function () {
          if (trace) {
            var screen_name = router.currentRouteName;
            trace.putAttribute('url', router.currentURL);
            trace.name = "".concat(screen_name, ":didTransition");
            trace.stop();

            _this2.set('trace', Ember.RSVP.reject());

            trace = undefined;
          }
        });
      });
    }
  });

  _exports.default = _default;
});