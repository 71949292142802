define('ember-impagination/components/impagination-dataset', ['exports', 'ember-impagination/templates/components/impagination-dataset'], function (exports, _impaginationDataset) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // TODO: Since `records` are wrapped in Ember.A() not all these methods are accurate
  // We should include Ember.Array methods here instead
  var accessors = ['concat', 'includes', 'join', 'slice', 'toString', 'toLocaleString', 'indexOf', 'lastIndexOf'];

  var iterators = ['forEach', 'every', 'some', 'filter', 'find', 'findIndex', 'keys', 'map', 'reduce', 'reduceRight', 'values'];

  var enumerables = ['objectAt'];

  var impaginationGetters = ['hasUnrequested', 'hasRequested', 'hasPending', 'hasResolved', 'hasRejected', 'hasUnfetchable', 'unrequested', 'requested', 'pending', 'resolved', 'rejected', 'unfetchable', 'pages', 'length', 'pageSize', 'loadHorizon', 'unloadHorizon', 'readOffset', 'stats', 'filter'];

  exports.default = Ember.Component.extend({
    layout: _impaginationDataset.default,
    tagName: '',
    'load-horizon': null,
    'unload-horizon': Infinity,
    'page-size': null,
    'fetch': null,
    'filter': null,
    'on-init': function onInit() {},
    'on-observe': function onObserve() {},


    // Impagination Dataset Class: `app/components/impagination-dataset`
    Dataset: null,

    init: function init() {
      this._super.apply(this, arguments);

      var readOffsetAttrFound = Ember.get(this, 'read-offset') >= 0;
      (false && Ember.warn('Ember Impagination: `read-offset` attribute has been removed. Please use the `on-init` function instead.', !readOffsetAttrFound, { id: 'ember-impagination.attributes.read-offset' }));

      this.fireOnInit();
    },


    fireOnInit: Ember.observer('dataset', function () {
      var model = this.get('model');
      this.get('on-init')(model);
    }),

    arrayActions: Ember.computed(function () {
      var _this = this;

      var context = this;
      var arrayActions = [].concat(accessors, iterators, enumerables).reduce(function (props, method) {
        var action = 'on-' + Ember.String.dasherize(method);
        if (Ember.get(_this, action)) {
          props[method] = function () {
            context[action].apply(context, [Ember.get(context, 'dataset')].concat(Array.prototype.slice.call(arguments)));
            return this._super.apply(this, arguments);
          };
        }
        return props;
      }, {});

      return Ember.Mixin.create(arrayActions);
    }),

    Model: Ember.computed('dataset', function () {
      var dataset = this.get('dataset');
      return Ember.Object.extend(Ember.Array, {
        delete: function _delete(index) {
          return dataset.delete(index);
        },
        put: function put(data, index) {
          return dataset.put(data, index);
        },
        post: function post(data, index) {
          return dataset.post(data, index);
        },
        reset: function reset(offset) {
          return dataset.reset(offset);
        },
        refilter: function refilter(callback) {
          return dataset.refilter(callback);
        },
        setReadOffset: function setReadOffset(offset) {
          return dataset.setReadOffset(offset);
        }
      });
    }),

    model: Ember.computed('enumerable', 'Model', function () {
      return this.get('Model').extend(Ember.get(this, 'enumerable'), Ember.get(this, 'arrayActions')).create();
    }),

    datasetState: Ember.computed('dataset', function () {
      return this.get('dataset').state;
    }),

    enumerable: Ember.computed('datasetState', function () {
      var datasetState = Ember.get(this, 'datasetState');

      // All Impaginaiton getters have to be made enumerable to be consumed by Ember.Array
      var enumerableProperties = impaginationGetters.reduce(function (props, getter) {
        props[getter] = {
          enumerable: true, get: function get() {
            return datasetState[getter];
          }
        };
        return props;
      }, {
        objectAt: {
          enumerable: true, value: function value(index) {
            return datasetState.getRecord(index);
          }
        }
      });

      return Object.create(datasetState, enumerableProperties);
    }),

    dataset: Ember.computed('page-size', 'load-horizon', 'unload-horizon', 'fetch', 'on-observe', 'filter', function () {
      var _this2 = this;

      var round = Math.round;
      var Dataset = Ember.get(this, 'Dataset');

      return new Dataset({
        pageSize: round(this.get('page-size')),
        loadHorizon: round(this.get('load-horizon')),
        unloadHorizon: round(this.get('unload-horizon')),
        fetch: this.get('fetch'),
        filter: this.get('filter'),
        observe: function observe(datasetState) {
          Ember.run.next(function () {
            if (_this2.isDestroyed) {
              return;
            }
            // eslint-disable-next-line ember/no-side-effects
            _this2.set('datasetState', datasetState); // TODO fix lint
            _this2.get('on-observe')(_this2.get('model'));
          });
        }
      });
    })
  });
});